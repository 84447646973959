@import url("https://fonts.googleapis.com/css?family=Roboto");

/* Scrollbar */

::-webkit-scrollbar{
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track{
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb{
  background: #007BFF;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
  background: #a5aaad;
}


/* Styles */

* {
  margin: 0;
  padding: 0;
  outline: none;
}

body {
  box-sizing: border-box;
  font-family: 'Roboto',sans-serif;
}

.text-primary-p{
  color: #a5aaad;
  font-size: 14px;
}

.container1{
  display: grid;
  height: 100vh;
  grid-template-columns: 0.6fr 3fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas: 
    "sidebar nav nav nav"
    "sidebar main main main";
} 

@media only screen and (max-width: 978px){
  .container1{
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas: 
      "nav"
      "main";
  }
}

/* Navbar */

.navbar{
  background: #ffffff;
  grid-area: nav;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  border-bottom: 1px solid lightgray;
}

.nav_icon{
  display: none;
}

@media only screen and (max-width: 978px){
  .nav_icon{
    display: inline;
  }
}

/* Sidebar */

#sidebar{
  background: #252529;;
  grid-area: sidebar;
  overflow-y: auto;
  padding: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.sidebar_title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f4f6;
  margin-bottom: 30px;
}

.sidebar_img{
  display: flex;
  align-items: center;
}

.sidebar_title > div > img{
  width:75px;
  object-fit: contain;
}

.sidebar_title > div > h1{
  font-size: 18px;
  display: inline;
}

.sidebar_title > i{
  font-size: 18px;
  display:none;
}

.sidebar_link:hover{
background-color: #0069d9;
}

.sidebar_menu > h2 {
  color: #99caff;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 10px;
}

.sidebar_link{
  color: #f3f4f6;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.active_menu_link{
  background: #007BFF;
  color: #FFFFFF;
}

.active_menu_link a{
  color: #FFFFFF !important;
}

.sidebar_link > a {
  text-decoration: none;
  color: #FFFFFF;
}

.sidebar_link > i{
  margin-right: 10px;
  font-size:18px;
}

.sidebar_responsive{
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
  height: 100vh;
}

#sidebarIcon{
  display: none;
}

@media only screen and (max-width: 978px){
  #sidebar{
    display: none;
  }

  #sidebarIcon{
    display: inline;
  }

  .sidebar_title > i {
    display: inline;
  }
}

/* Main */

main{
  background: #f3f4f6;
  grid-area: main;
  overflow-y: auto;
}

.main__container{
  padding: 20px 35px;
}

/* Login */

.main{
  background: linear-gradient(to right, #007BFF, #0069d9);
}

.vh-100{
  height: 100vh;
}

.login{
  border-radius: 5px !important;
  background-color: white;
  padding: 20px 20px 20px 20px;
}
